<template>
    <div class="grid-list">
        <ul class="grid-list__content" :class="classes">
            <slot />
        </ul>
    </div>
</template>

<script>
export default {
    name: 'GridList',
    props: {
        columns: {
            type: Number,
            default: 3,
        },
        twoXSColumns: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'grid-list__content--two-xs-cols': this.twoXSColumns,
                'grid-list__content--cols-3': this.columns === 3,
                'grid-list__content--cols-2': this.columns === 2,
            };
        },
    },
};
</script>
