<template>
    <static-content-page :title="$t('legal.dataPrivacy.title')">
        <div v-html="$t('legal.dataPrivacy.content')"></div>
    </static-content-page>
</template>

<script>
import StaticContentPage from '@/components/atoms/StaticContentPage.vue';

export default {
    name: 'DataProtection',
    components: { StaticContentPage },
};
</script>
