<template>
    <section
        id="contact"
        class="contact-section"
        :class="{
            'contact-section--submitted': submissionStatus === 'submitted',
        }"
    >
        <div class="contact-section__content">
            <transition name="fade">
                <form
                    v-if="
                        submissionStatus === 'open' ||
                        submissionStatus === 'error' ||
                        submissionStatus === 'submitting'
                    "
                    action=""
                    @submit.prevent="onSubmit"
                    ref="form"
                >
                    <h2>{{ $t('contact.headline') }}</h2>
                    <p>{{ $t('contact.subtitle') }}</p>

                    <div
                        v-if="submissionStatus === 'error'"
                        class="contact-section__content-error"
                    >
                        <h3>
                            <span>
                                {{ $t('contact.submitError.title') }}
                            </span>
                        </h3>
                        <p>
                            {{ $t('contact.submitError.message') }}
                        </p>
                    </div>

                    <TextInput
                        v-model="name"
                        :label="$t('contact.name.label')"
                        identifier="name"
                        :placeholder="$t('contact.name.placeholder')"
                        required
                        :disabled="isSubmitting"
                    ></TextInput>

                    <TextInput
                        v-model="email"
                        :label="$t('contact.email.label')"
                        identifier="email"
                        :placeholder="$t('contact.email.placeholder')"
                        required
                        :disabled="isSubmitting"
                        type="email"
                    ></TextInput>

                    <TextInput
                        v-model="message"
                        :label="$t('contact.message.label')"
                        identifier="message"
                        :placeholder="$t('contact.message.placeholder')"
                        multiline
                        required
                        :disabled="isSubmitting"
                    ></TextInput>

                    <toggle
                        v-model="dataProtection"
                        required
                        ref="dataProtectionToggle"
                        :label="$t('contact.acceptDataProtection')"
                        :disabled="isSubmitting"
                    />

                    <btn
                        :icon="[
                            'fas',
                            isSubmitting ? 'circle-notch' : 'paper-plane',
                        ]"
                        :icon-rotation="isSubmitting"
                        is-submit-btn
                        :disabled="isSubmitting"
                        >{{ $t('contact.submitBtn') }}</btn
                    >
                </form>
                <div v-else-if="submissionStatus === 'submitted'" id="success">
                    <div class="contact-section__content-success">
                        <h2>
                            <span>
                                {{ $t('contact.submitSuccess.title') }}
                            </span>
                            <fa-icon :icon="['fas', 'paper-plane']" />
                        </h2>
                        <p>
                            {{ $t('contact.submitSuccess.message') }}
                        </p>
                    </div>
                </div>
            </transition>
        </div>
    </section>
</template>

<script>
import TextInput from '@/components/atoms/TextInput.vue';
import Btn from '@/components/atoms/Btn.vue';
import Toggle from '@/components/atoms/Toggle.vue';
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
    name: 'ContactSection',
    components: { Toggle, Btn, TextInput },
    data() {
        return {
            name: '',
            email: '',
            message: '',
            dataProtection: false,
            isSubmitting: false,
            submissionStatus: 'open',
        };
    },
    created() {
        const storeState = window.localStorage.getItem('data');
        if (!storeState) return;

        const submissionDate = JSON.parse(storeState).contactSubmissionTime;
        if (!submissionDate) return;

        const submissionTime = new Date(submissionDate).getTime();
        const currentTime = new Date().getTime();

        // 24 hours in milliseconds
        const twentyFourHoursInMs = 24 * 60 * 60 * 1000;

        // Check if the submissionTime is within the last 24 hours
        if (
            currentTime - submissionTime < twentyFourHoursInMs &&
            submissionTime <= currentTime
        ) {
            this.submissionStatus = 'submitted';
        }
    },
    methods: {
        ...mapActions(['setContactSubmissionTime']),

        async onSubmit(e) {
            const valid = this.validateForm();

            if (valid) {
                this.isSubmitting = true;
                this.submissionStatus = 'submitting';

                try {
                    const data = {
                        name: this.name,
                        email: this.email,
                        message: this.message,
                    };
                    const result = await axios.post(
                        'https://formspree.io/f/mldrvpkj',
                        data,
                    );

                    if (result.status === 200 && result.data?.ok) {
                        this.submissionStatus = 'submitted';
                        this.setContactSubmissionTime(new Date());

                        return;
                    }

                    this.isSubmitting = false;
                    this.submissionStatus = 'error';
                } catch (e) {
                    this.isSubmitting = false;
                    this.submissionStatus = 'error';
                }
            }
        },
        validateForm() {
            if (this.submissionStatus === 'submitted') {
                return false;
            }

            let isValid = true;

            const textInputs =
                this.$refs.form.querySelectorAll('input, textarea');
            textInputs.forEach((input) => {
                if (!input.checkValidity()) {
                    isValid = false;
                    input.reportValidity();
                }
            });

            this.$refs.dataProtectionToggle.validateInput();
            if (this.$refs.dataProtectionToggle.isInputInvalid) {
                isValid = false;
            }

            return isValid;
        },
    },
};
</script>
