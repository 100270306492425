<template>
    <static-content-page class="resume" title="">
        <div class="resume-header">
            <h1>Marius <span>Gebhard</span></h1>
        </div>
        <section id="summary">
            {{ resume.info.brief }}
        </section>
        <hr />
        <section id="skills">
            <header>
                <h2>{{ $t('resume.skills') }}</h2>
            </header>
            <div v-for="skill in resume.skills.sets">
                <div>
                    <strong>{{ skill.name }}</strong>
                </div>
                <div class="keywords">
                    <span v-for="skill in skill.skills">{{ skill }}</span>
                </div>
            </div>
        </section>
        <hr />
        <section id="employment">
            <header>
                <h2>{{ $t('resume.employment') }}</h2>
            </header>
            <div class="employment-row">
                <div v-for="employment in resume.employment.history">
                    <h3>
                        <span>
                            <em>{{ employment.position }}</em>
                            <a
                                v-if="employment.url"
                                :href="employment.url"
                                target="_blank"
                                >{{ formatUrlName(employment.url) }}</a
                            >
                        </span>
                        <span class="tenure">
                            <fa-icon :icon="['far', 'clock']" />
                            <span>{{
                                $d(new Date(employment.start), 'short')
                            }}</span>
                            <span v-if="employment.end"
                                ><span> - </span>
                                {{
                                    $d(new Date(employment.end), 'short')
                                }}</span
                            ></span
                        >
                    </h3>
                    <p>
                        {{ employment.summary }}
                    </p>
                    <strong>{{ $t('resume.highlights') }}</strong>
                    <ul>
                        <li v-for="highlight in employment.highlights">
                            {{ highlight }}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </static-content-page>
</template>

<script>
import StaticContentPage from '@/components/atoms/StaticContentPage.vue';
import resume from '@/resume.json';

export default {
    name: 'Resume',
    components: { StaticContentPage },
    computed: {
        resume() {
            return resume;
        },
    },
    methods: {
        formatUrlName(url) {
            return url.replace(/(^\w+:|^)\/\//, '');
        },
    },
};
</script>
