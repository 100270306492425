import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import HomeView                                                 from '@/views/HomeView.vue';
import Imprint from '@/views/Imprint.vue';
import DataProtection from '@/views/DataProtection.vue';
import Resume from '@/views/Resume.vue';
import ProjectBase from '@/views/Project.vue';
import ProjectView from '@/views/ProjectView.vue';
import ProjectsOverview from '@/views/ProjectsOverview.vue';
import PageNotFound from '@/views/PageNotFound.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/projects',
        component: ProjectBase,
        children: [
            {
                path: '',
                name: 'project-overview',
                component: ProjectsOverview,
            },
            {
                path: ':slug',
                name: 'project-detail',
                component: ProjectView,
            },
        ],
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: Imprint,
    },
    {
        path: '/data-protection',
        name: 'data-protection',
        component: DataProtection,
    },
    {
        path: '/resume',
        name: 'resume',
        component: Resume,
    },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: PageNotFound },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            const delay = to.name !== from.name ? 300 : 0;

            setTimeout(() => {
                const element = document.querySelector(to.hash);
                const offset = -50;

                if (element) {
                    const elementPosition =
                        element.getBoundingClientRect().top +
                        window.scrollY +
                        offset;

                    window.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth',
                    });
                }
            }, delay);
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;
