<template>
    <scroll-section>
        <section id="projects" class="projects-section">
            <h2>{{ $t('projects.headline') }}</h2>
            <div
                v-if="currentSlide && currentIndex !== -1"
                class="projects-section__current"
            >
                <strong ref="currentIndex">{{
                    `${currentIndex + 1}`.padStart(2, '0')
                }}</strong>
                <span
                    ref="currentName"
                    v-html="currentSlide?.title[$i18n.locale]"
                ></span>
            </div>
            <div class="projects-section__slider">
                <slider
                    :items="projects"
                    slides-per-view="1.15"
                    slides-per-view-l="2"
                    slides-per-view-m="1.25"
                    controls
                    autoplay
                    loop
                    :centered-slides="false"
                    ref="slider"
                    @slide-change="onSlideChange"
                >
                    <template #item="{ item, i, active }">
                        <project-teaser
                            :project="item"
                            :is-active="active"
                            @inactiveClicked="$refs.slider.slideTo(i)"
                        />
                    </template>

                    <template #controls>
                        <btn type="transparent" to="projects">{{
                            $t('projects.viewAll')
                        }}</btn>
                    </template>
                </slider>
            </div>
        </section>
    </scroll-section>
</template>

<script>
import Slider from '@/components/atoms/Slider.vue';
import ProjectTeaser from '@/components/molecules/ProjectTeaser.vue';
import { mapActions, mapState } from 'vuex';
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'ProjectsSection',
    components: { Btn, ProjectTeaser, Slider },
    data() {
        return {
            currentSlide: null,
            currentIndex: -1,
        };
    },
    computed: {
        ...mapState(['currentSlideIndex', 'projects']),
    },
    mounted() {
        this.onSlideChange(0);
    },
    methods: {
        ...mapActions(['setCurrentSlideIndex']),

        onSlideChange(slideIndex) {
            this.currentIndex = slideIndex;
            this.currentSlide = this.projects[slideIndex];

            this.setCurrentSlideIndex(slideIndex);
        },
    },
};
</script>
