<template>
    <scroll-section>
        <section class="intro-section" ref="introSection">
            <h1 class="intro-section__title" v-html="$t('intro.headline')" />
            <hr class="intro-section__divider" />
            <p
                class="intro-section__subtitle"
                v-html="$t('intro.subtitle')"
            ></p>

            <div class="intro-section__buttons">
                <btn to="/#contact" type="hero" :icon="['fas', 'envelope']">
                    {{ $t('intro.contactBtn') }}
                </btn>
                <btn to="/#about" type="hero" :icon="['fas', 'code']">
                    {{ $t('intro.servicesBtn') }}
                </btn>
            </div>

            <button class="intro-section__arrow" @click="onArrowClick">
                <fa-icon :icon="['fas', 'arrow-down']" />
            </button>
        </section>
    </scroll-section>
</template>

<script>
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'IntroSection',
    components: { Btn, FaIcon },
    methods: {
        onArrowClick() {
            const elementPosition =
                this.$refs.introSection.getBoundingClientRect().height + 30;

            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            });
        },
    },
};
</script>
