<template>
    <scroll-section>
        <div v-if="project" class="project-view">
            <router-link
                :to="{ name: 'project-overview' }"
                class="static-content-page__back"
            >
                <fa-icon :icon="['fas', 'arrow-down']" />
                {{ $t('content.gotoProjects') }}
            </router-link>

            <div v-if="project" class="project-view__content">
                <div class="project-view__content-title">
                    <h1 v-html="project.title[$i18n.locale]"></h1>

                    <div class="project-view__content-date">
                        <fa-icon :icon="['far', 'clock']" />
                        <span>{{
                            $d(new Date(project.startDate), 'short')
                        }}</span>
                        <i>-</i>
                        <span>{{
                            $d(new Date(project.endDate), 'short')
                        }}</span>

                        <ul>
                            <li v-for="tag in project.tags">
                                {{ tag }}
                            </li>
                        </ul>
                    </div>
                </div>

                <p v-html="project.description[$i18n.locale]"></p>

                <div
                    v-if="project.images?.length"
                    class="project-view__content-images"
                >
                    <grid-list :columns="2">
                        <img
                            v-for="img in project.images"
                            :src="require(`@/assets/images/${img}`)"
                            alt=""
                        />
                    </grid-list>
                </div>
            </div>
        </div>
        <page-not-found
            v-else
            :back-page="{
                to: 'project-overview',
                label: 'content.gotoProjects',
            }"
        />
    </scroll-section>
</template>

<script>
import Projects from '@/assets/projects.json';
import PageNotFound from '@/views/PageNotFound.vue';
import GridList from '@/components/atoms/GridList.vue';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import ScrollSection from '@/components/atoms/ScrollSection.vue';

export default {
    name: 'ProjectView',
    components: { ScrollSection, FaIcon, GridList, PageNotFound },
    computed: {
        project() {
            return Projects.find((p) => p.slug === this.$route.params?.slug);
        },
    },
};
</script>
