import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import Projects from '@/assets/projects.json';
import Services from '@/assets/services.json';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        language: state.language,
        contactSubmissionTime: state.contactSubmissionTime,
    }),
    key: 'data',
});

export default createStore({
    plugins: [vuexLocal.plugin],
    state: {
        currentSlideIndex: 0,
        scrolled: false,
        scrolledWide: false,
        language: 'en',
        projects: Projects,
        services: Services,
        contactSubmissionTime: null,
    },
    mutations: {
        SET_CURRENT_SLIDE_INDEX(state, slideIndex) {
            state.currentSlideIndex = slideIndex;
        },
        SET_SCROLLED(state, { scrolled, scrolledWide }) {
            state.scrolled = scrolled;
            state.scrolledWide = scrolledWide;
        },
        SET_LANGUAGE(state, lang) {
            state.language = lang;
        },
        SET_CONTACT_SUBMISSION_TIME(state, time) {
            state.contactSubmissionTime = time;
        },
    },
    actions: {
        setCurrentSlideIndex({ commit }, slide) {
            commit('SET_CURRENT_SLIDE_INDEX', slide);
        },
        setScrolled({ commit }, data) {
            commit('SET_SCROLLED', data);
        },
        setLanguage({ commit }, lang) {
            commit('SET_LANGUAGE', lang);
        },
        setContactSubmissionTime({ commit }, time) {
            commit('SET_CONTACT_SUBMISSION_TIME', time);
        },
    },
});
