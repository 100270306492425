<template>
    <div class="static-content-page">
        <router-link to="/" class="static-content-page__back">
            <fa-icon :icon="['fas', 'arrow-down']" />
            {{ $t('content.goBack') }}
        </router-link>
        <div class="static-content-page__header">
            <h1>{{ title }}</h1>
        </div>

        <div class="static-content-page__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StaticContentPage',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
