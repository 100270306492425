<template>
    <div class="toggle" :class="classes" @click.stop="toggleCheckbox">
        <input
            type="checkbox"
            class="toggle__input"
            :id="identifier"
            :name="identifier"
            :disabled="disabled"
            :checked="isChecked"
            :required="required"
            ref="checkbox"
            readonly
        />
        <div class="toggle__check">
            <span class="toggle__check-background">
                <fa-icon :icon="['fas', 'check']" />
            </span>
        </div>
        <div class="toggle__labels">
            <span v-if="label" v-html="label"></span>
            <em v-if="subtitle">{{ subtitle }}</em>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    emits: ['update:modelValue'],
    props: {
        identifier: {
            type: String,
        },
        modelValue: {},
        label: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isInputInvalid: false,
        };
    },
    computed: {
        isChecked() {
            return this.modelValue === true || this.modelValue === 'true';
        },
        classes() {
            return {
                'toggle--invalid': this.isInputInvalid,
                'toggle--checked': this.isChecked,
                'toggle--disabled': this.disabled,
            };
        },
    },
    methods: {
        toggleCheckbox() {
            if (!this.disabled) {
                if (typeof this.modelValue === 'boolean') {
                    this.$emit('update:modelValue', !this.modelValue);
                } else if (typeof this.modelValue === 'string') {
                    this.$emit(
                        'update:modelValue',
                        this.modelValue === 'true' ? 'false' : 'true',
                    );
                }
                this.$nextTick(this.validateInput);
            }
        },
        validateInput() {
            const inputElement = this.$refs.checkbox;
            this.isInputInvalid = this.required && !inputElement.checked;
        },
    },
    watch: {
        modelValue() {
            this.validateInput();
        },
    },
};
</script>
