<template>
    <div class="footer-section">
        <div class="container">
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-12 col-m-6 col-xl-4 footer-info-widget">
                        <div class="footer-widget">
                            <!--                            <div class="footer__logo">-->
                            <!--                                <a href="/"-->
                            <!--                                    ><img-->
                            <!--                                        src="/themes/custom/basic_theme/logo.svg"-->
                            <!--                                        alt="Company logo"-->
                            <!--                                /></a>-->
                            <!--                            </div>-->
                            <div class="footer-text">
                                <p>
                                    {{ $t('footer.text') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-m-6 col-xl-4 footer-links-widget">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>{{ $t('footer.quicklinks') }}</h3>
                            </div>

                            <nav
                                role="navigation"
                                aria-labelledby="block-basic-theme-footer-menu"
                                id="block-basic-theme-footer"
                            >
                                <ul class="menu">
                                    <li
                                        v-for="link in footerContent.quickLinks"
                                        class="menu-item"
                                    >
                                        <router-link
                                            v-bind="{
                                                to: link.to.includes('#')
                                                    ? link.to
                                                    : { name: link.to },
                                            }"
                                            >{{
                                                link.title[$i18n.locale]
                                            }}</router-link
                                        >
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-12 col-m-6 col-xl-4 footer-socials-widget">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>{{ $t('footer.socials') }}</h3>
                            </div>
                            <div class="footer-social-icon">
                                <nav role="navigation">
                                    <ul class="social-icons">
                                        <li
                                            v-for="social in footerContent.socials"
                                        >
                                            <a
                                                :href="social.link"
                                                :title="social.title"
                                                target="_blank"
                                                ><fa-icon
                                                    :icon="[
                                                        social.noFab
                                                            ? 'fas'
                                                            : 'fab',
                                                        social.icon,
                                                    ]"
                                            /></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="copyright-text">
                    <p>
                        © {{ new Date().getFullYear() }} Marius Gebhard -
                        Freelancer
                    </p>
                </div>
                <div class="footer-menu">
                    <nav role="navigation">
                        <ul class="menu">
                            <li
                                v-for="link in footerContent.legalLinks"
                                class="menu-item"
                            >
                                <router-link :to="link.to">{{
                                    link.title[$i18n.locale]
                                }}</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FooterContent from '@/assets/menu-content.json';
export default {
    name: 'FooterSection',
    computed: {
        footerContent() {
            return FooterContent;
        },
    },
};
</script>
