<template>
    <div class="text-input-wrapper">
        <div class="text-input" :class="classes">
            <label v-if="label" :for="identifier" class="text-input__label">{{
                label
            }}</label>
            <div v-if="icon" class="text-input__icon fas" :class="icon"></div>
            <component
                :is="multiline ? 'textarea' : 'input'"
                :type="type"
                :id="identifier"
                class="text-input__input"
                :value="modelValue"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                ref="input"
                v-bind="multiline ? { rows: 5 } : {}"
                @input="onInput"
                @focus="validateInput"
                @blur="validateInput"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    emits: ['update:modelValue'],
    props: {
        modelValue: {},
        identifier: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiline: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
        },
    },
    data() {
        return {
            isInputInvalid: false,
        };
    },
    methods: {
        onInput(e) {
            this.$emit('update:modelValue', e.target.value);
            this.validateInput();
        },
        validateInput() {
            const inputElement = this.$refs.input;
            this.isInputInvalid = inputElement && !inputElement.checkValidity();
        },
    },
    computed: {
        classes() {
            return {
                'text-input--multiline': this.multiline,
                'text-input--has-label': this.label,
                'text-input--invalid': this.isInputInvalid,
                'text-input--disabled': this.disabled,
            };
        },
    },
};
</script>
