<template>
    <div class="projects-overview">
        <router-link to="/#projects" class="static-content-page__back">
            <fa-icon :icon="['fas', 'arrow-down']" />
            {{ $t('content.goBack') }}
        </router-link>

        <div class="static-content-page__header">
            <h1>{{ $t('projects.overviewHeadline') }}</h1>
            <p>{{ $t('projects.description') }}</p>
        </div>
        <GridList :columns="2">
            <li v-for="project in projects">
                <project-teaser :project="project" is-active />
            </li>
        </GridList>
    </div>
</template>

<script>
import GridList from '@/components/atoms/GridList.vue';
import ProjectTeaser from '@/components/molecules/ProjectTeaser.vue';
import { mapState } from 'vuex';

export default {
    name: 'ProjectsOverview',
    components: { ProjectTeaser, GridList },
    computed: {
        ...mapState(['projects']),
    },
};
</script>
